export const is3WSD18 = false;
export const isFLN = false;
export const isSSM508 = false;
export const isFOOKLEEUNION = false;
export const isHY202318 = false;
export const isTCM941 = false
export const isCosmic= false
export const is2WSD23= true
export const is1026E24T =false;
export const isTcj911 = false

export const COOKIESPREFIX = 'CONSTRUCTIONDASHBOARD';

export const isOnlyTracking = isCosmic
export const showTrackingLocation = isCosmic||is2WSD23
export const showTrackingStatus = isCosmic
export const showTrackingPhoto = isCosmic
export const isHideMenuButton = true
export const defaultLogoName = isCosmic?'Aquila':'Novalinx'
export const isWatchDangerZone = isSSM508||is2WSD23
export const showTrackingExaminationDate = is2WSD23
export const showPermitLocation = is2WSD23


export const DEFAULT_PROJECT_ID =  
    isFLN?'FLN'
    :is3WSD18?"3WSD18"
    :isSSM508?'SSM508'
    :isFOOKLEEUNION?'Fook Lee Construction Co., Ltd. Union Contractors Ltd.'
    :isHY202318?'HY/2023/18'
    :isTCM941?'tcm941'
    :isCosmic? 'Cosmic'
    :is2WSD23? '2/WSD/23'
    :is1026E24T? '1026E24T'
    :isTcj911? 'tcj911'
    :'Project 1';

export const isHideCMP = isCosmic||false
export const isHideSummary = isCosmic||false
export const isHideDangerZone=(isTcj911||is1026E24T||isCosmic||is3WSD18||isHY202318||isFOOKLEEUNION)?true:false
export const isHideConfinedSpace=(isTcj911||is1026E24T||isCosmic||is2WSD23||isTCM941||is3WSD18||isHY202318||isFOOKLEEUNION)?true:false
export const isHideSmartLock = (is1026E24T||isCosmic||is3WSD18||isHY202318)?true:false
export const isHideSmartWatch = isCosmic||false
export const isHideSmartCamera= (is1026E24T||isCosmic||is3WSD18)?true:false
export const isHidePermit= isCosmic||is3WSD18||false
export const isHideTracking= is1026E24T||is3WSD18||false
export const isHideVR = isTcj911||is1026E24T||is3WSD18||isHY202318||isCosmic||false
export const isHidePermitCreateDate = is2WSD23
export const isPrintWhiteMode = is2WSD23

export const CMP_DEFAULT_FILTER= isFOOKLEEUNION? '7 Days' :"Today"

export const REFRESH_INTERVAL = 1000*30 //30 seconds 

export const PERMIT_DEFAULT_TYPE = 
    isTCM941?['Sham Shui Po','Tsuen Wan','Kwai Tsing']
    :isFOOKLEEUNION? ['TCM942','TCM932','901']
    :['Permit to Work', 'Permit to Move', 'Permit to Operate', 'Other Permit']

export const server_ip = ''
//demo:'http://18.140.58.38:3000'
//3WSD:'http://18.143.73.17'
//FLN: 'http://13.212.187.22'
//SSM508: 'http://52.221.243.77'
//FOOKLEEUNION: 'http://18.143.149.108'
//HY/2023/18: 'http://13.250.51.217'
//tcm941: 'http://54.151.167.226'
//cosmic: 'http://13.250.64.40'
//2/WSD/23: 'http://13.229.101.255'
//1026E24T 'http://13.215.162.68'
//tcj911 'http://18.166.61.231'

export const GOOGLEMAPAPIKEY = 'AIzaSyBP6_3_nGzAaL1ti6OmphwQvqFI5VhYguc';