import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
//svgs
import SmallHeader from '../../../svgs/tracking/SmallHeader'
import FormDate from './formComponets/FormDate';
import GreyCalendar from '../../../svgs/permit/GreyCalendar'
import BigBluePlus from '../../../svgs/permit/BigBluePlus'
import UploadImg from '../../../svgs/permit/UploadImg'
import TickSvg from '../../../svgs/permit/FormTick'
import ImgClose from '../../../svgs/permit/ImgClose'
import BinSvg from '../../../svgs/permit/GrayBin'
//
import { server_ip, showPermitLocation } from '../../../config';
import {yearMonthDay} from '../../../actions/actionsDateTime'
//
import { fetchUsersRole } from '../../../services/permit.service';
//
function FormStatus({hasPermission, token,control,handleDeleteExistingFile, isPreview, updateTemplateDataWithKey = () => {}, templateData, handleHaveAttachement=()=>{}, handleUpdateRoles}) {
    const fileUploadRef = useRef()
    const [imgPreview, setImgPreview] = useState([])
    const [projectUserRoles, setProjectUserRoles] = useState({})
    const [permitPopType, setPermitPopType] = useState(null)
    const [targetPermitList, setTargetPermitList] = useState([])
    const [selectedPermitList, setselectedPermitList] = useState([])
    const [errMsg, setErrMsg] = useState('')
    const [errMsgTime, setErrMsgTime] = useState('')
    useEffect(() => {
        handleFetchUsersRole()
    },[control, hasPermission])
    async function handleFetchUsersRole(){
        if(control.userData._id !== templateData?.createdBy) return;
        const res = await fetchUsersRole(token.token, control.projectId)
        if(res.errno) return
        setProjectUserRoles(res.result)
    }
    function handleFileChange(e){
        if(isPreview)return;
        const newItem = new FormData();
        const imgElement = []
        const imgIds = []
        for(let i = 0; i < e.target.files.length; i++){
            newItem.append(e.target.files[i].name, e.target.files[i])
            const objectURL = URL.createObjectURL(e.target.files[i]);
            imgElement.push({objectURL, type:e.target.files[i].type})
            imgIds.push({id:`${templateData.template_id}_${i}_${new Date().getTime()}`,type: e.target.files[i].type})
        }

        setImgPreview(imgElement)
        handleHaveAttachement(newItem)

        updateTemplateDataWithKey('newAttachments', imgIds)

    }
    function handlePermitPop(type){
        setPermitPopType(type)
    }
    function handleUpdateApprove(idx, val){
        if(isPreview)return;
        const currentList = [...templateData.approvers]
        if(val === -1){
            currentList[idx].isApproved = -1
            updateTemplateDataWithKey('approvers', currentList)
            return;
        }
        const lastPerson = currentList.reduce((accum, curr) => {
            if(curr.isApproved <= 0) accum++
            return accum
        },0)
        if(lastPerson === 1){
            setErrMsg('')
            currentList[idx].isApproved = val
            updateTemplateDataWithKey('approvers', currentList)
            updateTemplateDataWithKey('status', 'Approved')
        }else{
            currentList[idx].isApproved = val
            updateTemplateDataWithKey('approvers', currentList)
        }
    }
    function handleUpdateTemplate(key, val){
        if(isPreview)return;
        if(hasPermission && key === 'status' && val === 'Pending for approval'){
            let notvalid = false
            templateData.form.forEach((section, idx) => {
                section.data.forEach((field, fIdx) => {
                    if(field.type === 'Text input'){
                        if(field.required){
                            // console.log(typeof field.fieldData.data !== 'string', field.fieldData.data === '')
                            if((typeof field.fieldData.data !== 'string' || field.fieldData.data === '')) notvalid = true
                        }
                    }
                    if(field.type === 'Date'){
                        if(field.required){
                            // console.log(typeof field.fieldData.data !== 'string', field.fieldData.data === '')
                            if((typeof field.fieldData.data !== 'string' || field.fieldData.data === '')) notvalid = true
                        }
                    }
                })
            })
            if(notvalid){
                setErrMsg('Required information missing!')
                return setTimeout(() => {setErrMsg('')}, 2000)
            }
        }
        // if(key === 'validFrom'){
        //     if(val < new Date()){
        //         setErrMsgTime('Invalid date!')
        //         setTimeout(() => {setErrMsgTime('')}, 2000)
        //         return
        //     }
        //     if(templateData.validTo && val > new Date(templateData.validTo)){
        //         setErrMsgTime('Invalid date!')
        //         setTimeout(() => {setErrMsgTime('')}, 2000)
        //         return
        //     } 
        // }
        // if(key === 'validTo'){
        //     if(!templateData.validFrom){
        //         setErrMsgTime('Select Valid From!')
        //         setTimeout(() => {setErrMsgTime('')}, 2000)
        //         return
        //     }
        // }
        if(hasPermission) updateTemplateDataWithKey(key,val)
    }
    return (<div className=''>
        {permitPopType && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex:9}}>
            <div className='relative p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPermitPopType(null)}}>X</div>      
                <div className='flex flex-row items-center space-between w-full'>
                    <div className='font-bold pb-2'>{permitPopType}</div>          
                </div>
                <div className='w-full flex flex-row gap-2' style={{pointerEvents: templateData?.status === 'Approved' ? 'none' : 'auto'}}>
                    <div className='w-[400px] h-[400px] p-[12px] overflow-y-auto'>
                        <div className='font-bold'>{permitPopType} List:</div>
                        <div className='mt-2'>
                            {projectUserRoles[permitPopType === 'Approvers' ? 'approvers' : 'users'] && projectUserRoles[permitPopType === 'Approvers' ? 'approvers' : 'users'].map((obj) => {
                                if(templateData[permitPopType === 'Approvers' ? 'approvers' : 'viewers'].some((currObj) => currObj?.username === obj.username)){
                                    return <span key={'random span' + Math.random()}/>
                                }
                                return <div key={obj._id} className='w-full flex flex-row justify-between py-1'>
                                    {obj.username}
                                    <BigBluePlus onClick={() => {handleUpdateRoles(permitPopType === 'Approvers' ? 'approvers' : 'viewers',obj)}}/>   
                                </div>
                            })}
                        </div>
                    </div>
                    <div className='w-[400px] h-[400px] p-[12px] overflow-y-auto'>
                        <div className='font-bold'>Selected {permitPopType}:</div>
                        <div className='mt-2'>
                            {templateData[permitPopType === 'Approvers' ? 'approvers' : 'viewers'].map((obj) => {
                                return <div key={obj._id} className='w-full flex flex-row justify-between py-1'>
                                    <div className='flex flew-row gap-2 items-center'>
                                        <div className=''>{obj.username}</div>
                                        {permitPopType === 'Approvers' && <div style={{color: obj.isApproved === -1 ? 'gray' : obj.isApproved ? 'green' : 'red'}}>{obj.isApproved === -1 ? 'Pending' : obj.isApproved ?  'Approved' : 'Not approved'}</div>}
                                    </div>
                                    <BinSvg onClick={() => {handleUpdateRoles(permitPopType === 'Approvers' ? 'approvers' : 'viewers',obj)}}/>   
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        {/*Location*/}
        {showPermitLocation&&<div className='flex flex-row font-bold'><SmallHeader className='mr-[2px] mb-[32px]'/>Location<span className='text-[red] ml-2'>{errMsg}</span></div>}
        {showPermitLocation&&<input defaultValue={(templateData?.location || '')} onChange={(e)=>{updateTemplateDataWithKey('location',e.target.value)}} required={false} className='border border-[#FFFFFF80] rounded-full bg-transparent h-[33px] px-[12px] w-[380px]' placeholder='Location'/>}
        {showPermitLocation&&<div className='w-full h-[1px]  my-[32px] bg-[#FFFFFF4D]'></div>}
        {/* Status */}
        <div className='flex flex-row font-bold'><SmallHeader className='mr-[2px] mb-[32px]'/>Status<span className='text-[red] ml-2'>{errMsg}</span></div>
        {['Draft', 'Pending for approval','Cancelled'].map((status, statusIdx) => (
            <div key={status + statusIdx}className='flex flex-row items-center mb-[16px]' style={templateData?.status !== 'Approved'? {} : {opacity:'50%'}}>
                <label className={'relative w-[24px] h-[24px] p-[1px] border mr-[12px] flex flex-row items-center justify-center'} style={templateData?.status !== 'Approved'? {cursor:'pointer'} : {opacity:'50%'}}>
                    <input className='absolute opacity-0 cursor-pointer' onChange={() => {if(isPreview)return;if(templateData.status !== 'Approved')handleUpdateTemplate('status',status)}} type="checkbox" />
                    {/* <div style={{backgroundColor: templateData?.status === status ? 'white' : 'transparent'}} className='h-full w-full rounded-full'></div> */}
                    <TickSvg width={24} style={{opacity: templateData?.status === status ? 100 : 0}} className='absolute'/>
                </label>
                <div>{status}</div>
            </div>
        ))}
        <div key='allApproved' className='flex flex-row items-center mb-[16px]' style={{opacity: templateData?.status === 'Approved' ? 100 : '50%'}}>
            <label className='relative w-[24px] h-[24px] p-[1px] border mr-[12px] flex flex-row items-center justify-center'>
                <input className='absolute opacity-0 cursor-pointer'  type="checkbox" />
                {/* <div style={{backgroundColor: templateData?.status === status ? 'white' : 'transparent'}} className='h-full w-full rounded-full'></div> */}
                <TickSvg width={24} style={{opacity: templateData?.status === 'Approved' ? 100 : 0}} className='absolute'/>
            </label>
            <div>Approved</div>
        </div>
        <div className='w-full h-[1px]  my-[32px] bg-[#FFFFFF4D]'></div>
        {/* Valid Time */}
        <div className='flex flex-row font-bold'><SmallHeader className='mr-[2px]'/>Valid Time <span className='text-[red] ml-2'>{errMsgTime}</span></div>
        <div className='flex flex-row' style={{pointerEvents: templateData?.status === 'Approved' ? 'none' : 'auto'}}>
            <div key={'from'} className={'flex flex-col px-[12px] mt-[24px]' + (' w-[50%]')}>
                <label className='mb-[8px] text-[16px]'>From*</label>
                <div className='border border-[#FFFFFF80] rounded-full h-[33px] flex flex-row items-center px-[12px]'>
                    {/* <GreyCalendar className='mr-[6px]'/> */}
                    <input disabled={!hasPermission} min={undefined} max={yearMonthDay(new Date(templateData?.validTo)) || undefined} defaultValue={yearMonthDay(templateData?.validFrom) || undefined} onChange={(e) => handleUpdateTemplate('validFrom', new Date(e.target.value))} type='date' required={false} className='bg-transparent h-[33px] w-full outline-none' style={{colorScheme: 'dark'}}></input>
                </div>
            </div>
            <div key={'to'} className={'flex flex-col px-[12px] mt-[24px]' + (' w-[50%]')}>
                <label className='mb-[8px] text-[16px]'>To*</label>
                <div className='border border-[#FFFFFF80] rounded-full h-[33px] flex flex-row items-center px-[12px]'>
                    {/* <GreyCalendar className='mr-[6px]'/> */}
                    <input disabled={!hasPermission} min={yearMonthDay(new Date(templateData?.validFrom)) || undefined} defaultValue={yearMonthDay(templateData?.validTo) || undefined} onChange={(e) => handleUpdateTemplate('validTo', new Date(e.target.value))} type='date' required={false} className='bg-transparent h-[33px] w-full outline-none' style={{colorScheme: 'dark'}}></input>
                </div>
            </div>
        </div>
        <div className='w-full h-[1px]  my-[32px] bg-[#FFFFFF4D]'></div>
        {/* Permit Workfow */}
        <div className='mb-[32px]'>
            <div className='flex flex-row font-bold'><SmallHeader className='mr-[2px]'/>Permit Workflow</div>
            <div className='text-[12px] text-[#797979]'>Approvers need to approve the Permit</div>
        </div>
        <div className='flex flex-row items-center' style={{pointerEvents: templateData?.status === 'Approved' ? 'none' : 'auto'}}>
            <label className='relative w-[24px] h-[24px] p-[1px] border mr-[12px] flex flex-row items-center justify-center'>
                <input disabled={templateData?.status === 'Pending for approval'} className='absolute opacity-0 cursor-pointer' onChange={() => handleUpdateTemplate('isAllApprovers', !templateData?.isAllApprovers)} type="checkbox" />
                {/* <div style={{backgroundColor: templateData?.isAllApprovers ? 'white' : 'transparent'}} className='h-full w-full'></div> */}
                <TickSvg width={24} style={{opacity: templateData?.isAllApprovers ? 100 : 0}}/>
            </label>
            <div>All Approvers need to approve in order {"(top to bottom)"}</div>
        </div>
        <div className='mt-[32px]' style={{pointerEvents: templateData?.status === 'Approved' ? 'none' : 'auto'}}>
            <div className='mb-[24px]'>
                <div className='flex flex-row'>Approvers</div>
                <div className='text-[12px] text-[#797979]'>Approvers can fill in the Permit</div>
                <div className='flex flex-row items-center mt-[8px]'>
                    <input disabled={true} defaultValue={(templateData?.approvers?.[0] ? templateData?.approvers?.[0]?.username + '...' : '')} required={false} className='border border-[#FFFFFF80] rounded-full bg-transparent h-[33px] px-[12px] w-[380px]' placeholder='Add approvers'></input>
                    {control.userData._id === templateData?.createdBy && <div className='rounded bg-[#00202A] h-[33px] w-[33px] ml-[8px]'>
                        <BigBluePlus className='cursor-pointer' onClick={() => {setPermitPopType('Approvers')}}/>
                    </div>}
                </div>
            </div>
            <div className='mb-[24px]'>
                <div className='flex flex-row'>Receivers</div>
                <div className='text-[12px] text-[#797979]'>Receiver need to confirm they read the Permit</div>
                <div className='flex flex-row items-center mt-[8px]'>
                    <input disabled={true}  defaultValue={templateData?.viewers?.[0] ? templateData?.viewers?.[0]?.username + '...' : ''} required={false} className='border border-[#FFFFFF80] rounded-full bg-transparent h-[33px] px-[12px] w-[380px]' placeholder='Add Receivers'></input>
                    {control.userData._id === templateData?.createdBy && <div className='rounded bg-[#00202A] h-[33px] w-[33px] ml-[8px]'>
                        <BigBluePlus className='cursor-pointer' onClick={() => {setPermitPopType('Receivers')}}/>
                    </div>}
                </div>
            </div>
            {templateData?.status === 'Approved' && <div>
                <div className='flex flex-row'>Approvers Action</div>
                <div className='text-[12px]'>Permit approved!</div>
            </div>} 
            {templateData?.status === 'Pending for approval' && <div className='mb-[24px]'>
                <div className='flex flex-row'>Approvers Action</div>
                <div className='flex flex-row items-center mt-[8px]'>
                    {templateData?.approvers && templateData?.approvers.map((obj, idx) => {
                        // if(obj.username !== control.userData.username) return <></>
                        if(obj?.username !== control.userData.username) return <span key={Math.random()}/>
                        if(templateData?.approvers[idx].isApproved === 1) return <div><span className='text-12 text-[lightgreen]'>You are <span className='font-bold'>approving</span> this permit, save permit to confirm!</span> <div className='p-2 border text-center mt-1 cursor-pointer rounded-[4px]' onClick={() => {handleUpdateApprove(idx, -1)}}> Cancel approval</div></div>
                        if(templateData?.approvers[idx].isApproved === 0) return <div><span className='text-12 text-[red]'>You are <span className='font-bold'>declining</span> this permit, save permit to confirm!</span> <div className='p-2 border text-center mt-1 cursor-pointer rounded-[4px]' onClick={() => {handleUpdateApprove(idx, -1)}}> Cancel decline</div></div>
                        if(templateData?.isAllApprovers){
                            let prevAllApproved = true
                            for(let i = 0; i < idx; i++){
                                if(templateData?.approvers[i].isApproved <= 0) prevAllApproved = false
                            }
                            if(idx === 0 || prevAllApproved){
                                return (
                                    <div>
                                        <div className='flex flex-row gap-4'>
                                            <div className='border p-1 cursor-pointer py-[2px] px-12 rounded-[4px]' onClick={() => handleUpdateApprove(idx, 1)}>Approve</div>
                                            <div className='border p-1 cursor-pointer py-[2px] px-12 rounded-[4px]' onClick={() => handleUpdateApprove(idx, 0)}>Decline</div>
                                        </div>
                                        {errMsg !== ''&& <div>{errMsg}</div>}
                                    </div>
                                )
                            }else{
                                return (
                                    <div>Waiting for previous approvers to approve.</div>
                                )
                            }
                        }else{
                            return (
                                <div>
                                    <div className='flex flex-row gap-4'>
                                        <div className='border p-1 cursor-pointer py-[2px] px-12 rounded-[4px]' onClick={() => handleUpdateApprove(idx, 1)}>Approve</div>
                                        <div className='border p-1 cursor-pointer py-[2px] px-12 rounded-[4px]' onClick={() => handleUpdateApprove(idx, 0)}>Decline</div>
                                    </div>
                                   {errMsg !== ''&& <div>{errMsg}</div>}
                                </div>
                            )
                        }
                    })}
                </div>
            </div>}
            {/* <div className='mb-[24px]'>
                <div className='flex flex-row'>Viewers</div>
                <div className='text-[12px] text-[#797979]'>Viewers get notification and can only view</div>
                <div className='flex flex-row items-center mt-[8px]'>
                    <input disabled={isPreview} required={false} className='border border-[#FFFFFF80] rounded-full bg-transparent h-[33px] px-[12px] w-[380px]' placeholder='Add user'></input>
                    <div className='rounded bg-[#00202A] h-[33px] w-[33px] ml-[8px]'>
                        <BigBluePlus className='cursor-pointer' />
                    </div>
                </div>
            </div> */}
            <div className='w-full h-[1px]  my-[32px] bg-[#FFFFFF4D]'></div>
        </div>
        <div>
            {/* Attachments */}
            <div className='w-full'>
                <div className='flex flex-row font-bold'>Attachments</div>
                <div className='mr-[24px] mt-[8px] h-[34px] py-[8px] border rounded-full inline-block cursor-pointer flex flex-row items-center justify-center w-[380px]' onClick={() => {fileUploadRef.current.click()}} style={{pointerEvents: templateData?.status === 'Approved' ? 'none' : 'auto'}}><UploadImg />Upload File</div>
                    {<form onChange={(e) => {handleFileChange(e)}}>
                        {/* <input disabled={![hasPermission, templateData?.status !== 'Pending for approval'].every((state) => state === true)} ref={fileUploadRef} type='file' multiple accept="image/png, image/jpeg, application/pdf" className='mt-[8px] py-[8px] px-[140px] border rounded-full inline-block hidden'></input> */}
                        <input disabled={![hasPermission].every((state) => state === true)} ref={fileUploadRef} type='file' multiple accept="image/png, image/jpeg, application/pdf" className='mt-[8px] py-[8px] px-[140px] border rounded-full inline-block hidden'></input>
                    </form>}
                </div>
                <div className='flex flex-row items-center gap-2 mt-[12px]'>
                    {imgPreview.length > 0 && imgPreview.map(({objectURL, type}) => {
                       if(type === 'application/pdf') return( <img key={Math.random()} src={'/pdficon.png'} className='blur-sm w-[64px] h-[64px]'></img>)
                       return( <img key={Math.random()} src={objectURL} className='blur-sm w-[64px] h-[64px]'></img>)
                    })}
                </div>
                <div>
                    <div className='mt-[12px]'>Existing Files</div>
                        <div className='flex flex-row mt-[12px] gap-2'>
                            {templateData?.attachments?.length > 0 && templateData.attachments.map(({id, type}, i) => 
                                {
                                    if(type.split('/')[1] === 'pdf'){
                                        return(<div key={id + i} className='overflow-visible relative w-[76px] h-[78px]'>
                                            <a href={server_ip+'/api/documents/'+id+'.'+ type.split('/')[1]} target='_blank'>
                                                <img className='absolute bottom-0 w-[68px] h-[68px]' src={'/pdficon.png'}></img>
                                            </a>
                                            <div style={{display: templateData?.status === 'Approved' ? 'none' : 'flex'}} onClick={() => handleDeleteExistingFile({id, type})} className='absolute right-0 cursor-pointer w-[20px] h-[20px] bg-[#5F5F5FD1] flex flex-row items-center justify-center rounded-full'>
                                                <ImgClose className=''/>
                                            </div>
                                        </div>)
                                    }else{
                                        return(<div key={id + i} className='overflow-visible relative w-[76px] h-[78px]'>
                                            <a href={server_ip+'/api/documents/'+id+'.'+ type.split('/')[1]} target='_blank'>
                                                <img className='absolute bottom-0 w-[68px] h-[68px]' src={server_ip+'/api/documents/'+id+'.'+ type.split('/')[1]}></img>
                                            </a>
                                            <div style={{display: templateData?.status === 'Approved' ? 'none' : 'flex'}} onClick={() => handleDeleteExistingFile({id, type})} className='absolute right-0 cursor-pointer w-[20px] h-[20px] bg-[#5F5F5FD1] flex flex-row items-center justify-center rounded-full'>
                                                <ImgClose className=''/>
                                            </div>
                                        </div>)
                                    }
                                })
                            }
                        </div>
                    </div>
            </div>
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(FormStatus)