import { useNavigate } from 'react-router-dom';
import SmallHeader from '../../svgs/tracking/SmallHeader'
import CopyLink from '../CopyLink';
//
import DetailArrowSvg from '../../svgs/summary/DetailArrowSvg'
import ConfinedSpaceAlertList from '../confinedSpace/ConfinedSpaceAlertList'
import { isPrintWhiteMode } from '../../config';
export default function Component(props){
    const isWhiteMode = props.isReport&&isPrintWhiteMode;
    const navigate = useNavigate()
    return(
        <div className="w-full flex flex-col"  style={{color:isWhiteMode?"#000":"#fff"}}>
            <div className="flex flex-row w-full justify-between items-center pb-[8px]" style={{}}>
                <div className="text-[16px] font-[300] flex flex-row items-center gap-2"><SmallHeader color={isWhiteMode?'#000':'#fff'}/>Confined Space Alerts<CopyLink color={isWhiteMode?'#000':'#fff'}/></div>
                {!props.isReport &&<div className='text-[12px] flex flex-row justify-center gap-2 cursor-pointer' onClick={() => {navigate('/confined-space/alert')}}>Details<DetailArrowSvg /></div>}
            </div>

            <ConfinedSpaceAlertList isReport={props.isReport}/>
        </div>
    )
}