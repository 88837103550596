import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../../config/style.config";
import GreyBin from '../../svgs/permit/GrayBin'
import LoadingPage from "../Loading";
import { fetchLockingEvent } from "../../services/locks.service";
import { deleteWatchAlert, updateEventById } from "../../services/watches.service";
import { getTimeOnly, getDateOnly } from "../../actions/actionsDateTime";
import { handleFilterDate } from "../../actions/actionsDateTime";
import DropdownMenu from '../DropdownMenu';
import { isPrintWhiteMode } from "../../config";
const CHART_TITLE = [
    {title: 'Item', field: null, flex: 1},
    {title: 'Serial Number', field: 'deviceID', flex: 2},
    {title: 'Device Name.', field: 'name', flex: 2},
    {title: 'Event', field: 'type', flex: 2},
    {title: 'Location', field: '', flex: 2},
    {title: 'Date', field: 'time', flex: 2},
    {title: 'Time', field: 'time', flex: 2},
    {title: 'Condition', field: 'condition', flex: 2},
    {title: 'Action', field: '', flex: 1},
]

function LockAlertList(props){
    const isWhiteMode= props.isReport&&isPrintWhiteMode;
    const [ChartData, setChartData] = useState([])
    const [targetDelete, setTargetDelete] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    useEffect(() => {
        fetchData()
    },[props.alertArr, searchParams])

    async function fetchData(){
        //console.log(props.alertArr)
        if(props.alertArr) return setChartData(props.alertArr)
        const filter = {}
        filter["type"] = {"$in": ["LOCK_VIBRATION", "LOCK_INAPR_UNLOCK"]}
        filter["projectId"] = props.control.projectId
        if(filterDate) filter['time'] = handleFilterDate(filterDate)
        if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)
        const res = await fetchLockingEvent(props.token.token, filter, {limit:999, sort: {time: -1}})
        if(res.errno) return
        setChartData(res.result)
    }
    function formatType(str){
            const obj = {
            "LOCK_UNLOCK":'Unlock',
            "LOCK_LOCK": "Lock",
            "LOCK_INAPR_UNLOCK": "Inappropriate unlock",
            "LOCK_VIBRATION": "Vibration",
        }
        return obj[str]
    }
    async function handleDelete(){
        setIsLoading(true)
        const res = await deleteWatchAlert(props.token.token, targetDelete._id)
        if(res.errno) return
        if(props.fetchData){
            props.fetchData()
        }else{
            fetchData()
        }
        setTargetDelete(null)
        setIsLoading(false)
    }
    const handleUpdateCondition=(event)=>async(item,index)=>{
        setIsLoading(true)
        const res = await updateEventById(props.token.token, event._id ,{condition:item})
        if(res.errno) {alert(res.message||'error');return}
        if(props.fetchData){
            props.fetchData()
        }else{
            fetchData()
        }
        setIsLoading(false)
    }
    return(
    <div className="py-[32px] px-[30px]">
        <div className="flex flex-row py-[16px] mt-5">
            {CHART_TITLE.map((obj, i) => (
                <div key={'title'+obj.title} className="table_header" style={{flex: obj.flex || 1, flexShrink: obj.srink ? 2 : 0,color:isWhiteMode?'#000':'#fff'}}>
                    {obj.title}
                </div>
            ))}
        </div>
        {targetDelete && 
            <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex: 999}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Confirm Delete Alert?</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => setTargetDelete(null)}>X</div>         

                    <div className='flex flex-row items-center space-between w-full mt-10'>
                        <div className='text-sm flex-1'>Name:</div>        
                        <div className='text-sm flex-2'>{targetDelete.name}</div>          
                    </div>
                    <div className='flex flex-row items-center space-between w-full mt-5'>
                        <div className='text-sm flex-1'>Alert Time:</div>       
                        <div className='text-sm flex-2'>{targetDelete?.time?new Date(targetDelete.time).toLocaleString():'-'}</div>          
                    </div>


                    <div onClick={handleDelete} className='button_blue w-full mt-10' style={{padding:'6px 0px'}}>
                        Delete
                    </div>
                </div>
                {isLoading && <LoadingPage />}
            </div>  
        }
        {ChartData.length > 0 && ChartData.map((items, idx) =>
            <div key={idx} className="table_row" style={{overflowWrap: 'break-word',backgroundColor:idx%2===1?'#FFFFFF1D':'transparent',overflow:'visible',color:isWhiteMode?'#000':'#fff'}}>
                {CHART_TITLE.map((h, i) => 
                <div key={h.item + 'inner' + i} className="table_item" style={{flex: h.flex,overflow:'visible'}}>{
                    h.inData ? items.data[h.inData] :
                    h.title ==='Fall Alert' ? (items.data[h.field] == 11 ? 'True' : '~') : 
                    h.inData ? (items.data[h.field] || '~') : 
                    h.title === 'Item' ? idx + 1 : 
                    h.title === 'Date' ? getDateOnly(items[h?.field]) :
                    h.title === 'Event' ? formatType(items[h.field]) :
                    h.title === 'Time' ?  getTimeOnly(items[h?.field]):
                    (h.title === 'Action' && props.control?.permissions?.SUMMARY_HIDE_ALERT) ? <GreyBin className='cursor-pointer' onClick={() => setTargetDelete({...items,idx})}/> :
                    // h.title === 'Event' ? <EventTypeComp TF={Math.random() <= 0.5}/> :
                    h.title === 'Location' ? <div className="text-[10px]">
                        <div>Lat: {typeof items.data?.lat === 'string' ? items.data?.lat : '~'}</div>
                        <div>Lng: {typeof items.data?.lng === 'string' ? items.data?.lng : '~'}</div>
                    </div> :
                    h.title === 'Condition' ? <div className="w-full"><DropdownMenu showColor={true} select={handleUpdateCondition(items)} minWidth={100} isFullWidth={true} value={items?.condition||'Pending'} options={['Pending','Testing','Resolved']}/></div>:
                    (items[h?.field]) || '-'
                }
            </div>)}
        </div>)}
        {isLoading && <LoadingPage />}
    </div>)
}

export default connect((store) => {
    return{
        token: store.tokenReducer,
        control: store.controlReducer,
    }
})(LockAlertList)